/* About Page CSS */
.about-page {
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Hero Section */
.about-hero {
  background-color: #0059b3;
  color: #ffffff;
  text-align: center;
  padding: 50px 20px;
}

.about-hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-weight: 700;
}

.about-hero-content p {
  font-size: 1.2rem;
  margin: 0 auto;
  max-width: 800px;
}

/* Mission and Values */
.about-mission {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.about-mission h2 {
  color: #003366;
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: 600;
}

.about-mission p {
  font-size: 1.1rem;
  margin: 0 auto;
  max-width: 800px;
}

.values-heading {
  padding-top: 30px;
}

.values-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  padding: 0 20px;
}

.values-list li {
  list-style: none;
  font-weight: bold;
  color: #333;
  background-color: #eef;
  padding: 15px 20px;
  border-radius: 10px;
}

/* Our Story */
.about-story {
  padding: 60px 20px;
  background-color: #ffffff;
  text-align: center;
}

.about-story h2 {
  color: #0059b3;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 600;
}

.about-story p {
  font-size: 1.1rem;
  margin: 0 auto;
  max-width: 800px;
  color: #333;
}

/* Why Ceramic Coatings Are Essential */
.about-coatings {
  padding: 50px 20px;
  background-color: #ffffff;
  text-align: center;
}

.about-coatings h2 {
  font-size: 2rem;
  color: #0059b3;
  margin-bottom: 20px;
  font-weight: 600;
}

.about-coatings p {
  font-size: 1.1rem;
  color: #333;
  max-width: 800px;
  margin: 0 auto 30px;
  line-height: 1.6;
}

/* High-Quality Equipment Section */
.quality-equipment {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.quality-equipment h2 {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 20px;
}

.quality-equipment p {
  font-size: 1.1rem;
  color: #333;
  max-width: 800px;
  margin: 0 auto 30px;
  line-height: 1.6;
}

/* Video Container */
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #ffffff; /* Set the background color for video container */
}

.video-container iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; /* Enforces 16:9 aspect ratio */
  max-height: 450px; /* Limits height to prevent excessive space */
}

/* Why Choose Us */
.why-choose-us {
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.why-choose-us h2 {
  text-align: center;
  color: #0059b3;
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: 600;
}

.choose-us-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.choose-card {
  background-color: #eef;
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0 auto;
}

.choose-card h3 {
  color: #0059b3;
  margin-bottom: 15px;
  font-size: 1.4rem;
  font-weight: 600;
}

.choose-card p {
  font-size: 1rem;
  color: #333;
}

/* Call to Action Section */
.about-cta {
  text-align: center;
  padding: 40px 20px;
  background-color: #0059b3;
  color: #ffffff;
}

.about-cta h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.about-cta .cta-button {
  background-color: #ffcd00;
  color: #333;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-cta .cta-button:hover {
  background-color: #cca400;
}

/* Focus styling for interactive elements */
.cta-button:focus,
.choose-card:focus {
  outline: 2px solid #ffcd00;
  outline-offset: 4px;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
  .about-hero-content h1 {
    font-size: 3rem;
  }

  .about-mission h2,
  .about-story h2,
  .why-choose-us h2,
  .quality-equipment h2 {
    font-size: 2rem;
  }

  /* Values List - 2x2 Grid on Tablets */
  .values-list {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .values-list li {
    flex: 0 0 45%;
  }
}

@media (min-width: 1024px) {
  /* Values List - Single Row on Desktop */
  .values-list {
    flex-direction: row;
    gap: 30px;
    justify-content: center;
  }

  .values-list li {
    flex: 0 0 20%;
  }

  .choose-us-cards {
    flex-direction: row;
  }
}
