.footer {
  background-color: #040404;
  color: #ffffff;
  padding: 40px 0;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 100%;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo img {
  height: 50px;
  margin-bottom: 20px;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.footer-logo img:hover {
  transform: scale(1.05);
}

.footer p {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #cccccc;
}

/* Social Links Styling */
.social-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Use gap for consistent spacing */
  margin-top: 15px;
}

.social-links a {
  color: #33bbff;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  text-decoration: none;
}

.social-links a:hover {
  color: #ffcd00;
  transform: scale(1.2);
}

.social-links a:focus {
  outline: 2px solid #ffcd00;
  outline-offset: 4px;
}

/* Legal Links Styling */
.legal-links {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  font-size: 0.9rem;
}

.legal-links a {
  color: #cccccc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: #ffcd00;
}

.legal-links a:focus {
  outline: 2px solid #ffcd00;
  outline-offset: 4px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-content {
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }

  .footer p {
    font-size: 0.9rem;
  }

  .social-links a {
    font-size: 1.2rem;
  }

  .legal-links {
    flex-direction: column;
    gap: 10px;
  }
}
