/* Gallery Layout */
.gallery {
  padding: 50px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.gallery-title {
  font-size: 2.5rem;
  color: #003366;
  margin-bottom: 30px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  justify-content: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .gallery-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

.gallery-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  max-width: 300px;
  margin: 0 auto;
}

.gallery-item:hover {
  transform: scale(1.05);
}

.gallery-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 10px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #000;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 10px;
}

.modal-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.prev-button,
.next-button {
  background-color: #0059b3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2rem;
}

.prev-button:hover,
.next-button:hover {
  background-color: #003366;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.modal-close:hover {
  color: #ffcd00;
}

/* Full Gallery Link Styling */
.full-gallery-link {
  text-align: center;
  margin-top: 30px;
}

.full-gallery-link .cta-button {
  background-color: #0059b3;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.full-gallery-link .cta-button:hover {
  background-color: #003366;
  transform: scale(1.05);
}

.full-gallery-link .cta-button:focus {
  outline: 2px solid #ffcd00;
  outline-offset: 4px;
}
