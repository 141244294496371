/* Full-width Call Banner */
.call-banner {
  background-color: #00509e; /* Darker blue for better contrast */
  color: #ffffff; /* White text for maximum readability */
  text-align: center;
  padding: 8px 0;
}

.call-link {
  color: #ffffff; /* Ensure text contrast */
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* No color change */
}

.call-link:hover,
.call-link:focus {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 10px rgba(0, 80, 158, 0.4); /* Soft shadow for hover focus */
  color: #ffffff; /* Ensures text color remains white on hover */
}

/* Main Header */
.header {
  background-color: #001f3f;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
  width: 100%;
}

/* Centered header content */
.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  position: relative;
}

/* Logo container */
.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 50px;
  height: auto;
}

.logo-text {
  height: 50px;
  object-fit: contain;
}

/* Hamburger button styling */
.hamburger {
  font-size: 2rem;
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  display: none; /* Hidden by default, shown only on smaller screens */
  position: absolute;
  right: 20px;
}

/* Full-width Navbar with No Padding */
.navbar {
  background-color: #003366;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  overflow: hidden;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.navbar ul li {
  margin: 0;
}

.navbar ul li a {
  display: block;
  padding: 12px 20px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.navbar ul li a:hover,
.navbar ul li a:focus {
  background-color: #0059b3;
  outline: 2px solid #ffcd00;
}

/* Display hamburger button and enable dropdown only on smaller screens */
@media (max-width: 1024px) {
  .hamburger {
    display: inline-flex; /* Show hamburger button on smaller screens */
  }

  .navbar {
    max-height: 0; /* Collapsed by default for mobile */
    transition: max-height 0.3s ease-out;
  }

  .navbar.open {
    max-height: 300px; /* Expands when open */
  }

  .navbar ul {
    flex-direction: column;
  }
}

@media (min-width: 1025px) {
  /* Hide hamburger button and display navbar fully on larger screens */
  .hamburger {
    display: none;
  }

  .navbar {
    max-height: none; /* Always visible on larger screens */
  }

  .navbar ul {
    flex-direction: row;
  }
}

/* Mobile and small-width adjustments */
@media (max-width: 600px) {
  .header-content {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .hamburger {
    position: static; /* Center hamburger below logo on small screens */
    margin-top: 10px;
  }
}
