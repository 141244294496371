/* Global Styles */

/* CSS Reset - Resets basic styles to ensure consistency across browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styling */
body {
  font-family: "Open Sans", sans-serif;
  background-color: #040404; /* Site-wide background color */
  color: #eee;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Headings */
h1, h2, h3 {
  font-family: "Poppins", sans-serif;
  color: #00aaff;
  margin-bottom: 20px;
}

/* Links */
a {
  color: #00aaff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0088cc;
}

/* Containers */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Add a common style to prevent large logos from exceeding their parent containers */
img {
  max-width: 100%;
  height: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}
