.service-areas {
    background-color: #f4f4f4;
    padding: 50px 20px;
    text-align: center;
  }
  
  .service-areas h2 {
    font-size: 2.2rem;
    color: #0059b3;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  .service-areas p {
    font-size: 1.1rem;
    color: #333;
    max-width: 800px;
    margin: 0 auto 20px;
  }
  
  .areas-list {
    list-style: none;
    padding: 0;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  .areas-list li {
    background-color: #0059b3;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1rem;
  }
  
  .about-button {
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff;
    background-color: #0059b3;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .about-button:hover {
    background-color: #003366;
  }
  
  .about-button:focus {
    outline: 3px solid #ffcd00;
    outline-offset: 4px;
  }
  