.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Testimonials Section */
.testimonials-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  color: #003366;
  margin-bottom: 40px;
  font-family: 'Poppins', sans-serif;
}

.testimonials-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Testimonial Card */
.testimonial-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  overflow: hidden;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-card:focus-visible {
  box-shadow: 0 0 10px #0059b3;
  outline: 3px solid #0059b3;
}

/* Carousel Container */
.carousel {
  width: 100%;
  height: 200px; /* Set a fixed height for uniformity */
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid #eee; /* Adds a subtle divider */
}

.testimonial-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  object-fit: cover; /* Maintains aspect ratio and crops if necessary */
}

.testimonial-image.active {
  opacity: 1;
}

.testimonial-content {
  padding: 20px;
  text-align: center;
}

.testimonial-review {
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  color: #444444; /* Darker gray for better readability */
  margin-bottom: 15px;
  line-height: 1.6; /* Improve readability */
}

.testimonial-rating {
  margin-bottom: 15px;
}

.star-icon {
  color: #ffcd00;
  font-size: 1.5rem;
  margin: 0 3px;
}

.testimonial-name {
  font-size: 1.5rem; /* Increase font size for improved readability */
  font-family: 'Poppins', sans-serif;
  color: #003366;
  margin-top: 10px;
  font-weight: bold; /* Emphasize names */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .testimonials-grid {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-card {
    width: 90%;
    max-width: 600px;
  }

  .testimonial-image {
    height: 180px; /* Reduce height slightly for smaller screens */
  }
}

/* Read More Button */
.read-more-button {
  display: inline-block;
  margin-top: 30px;
  padding: 12px 20px;
  background-color: #0059b3; /* Stronger contrast blue */
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.read-more-button:hover,
.read-more-button:focus {
  color: white;
  background-color: #003366; /* Darker blue for hover */
  transform: scale(1.05); /* Slight zoom effect */
  outline: 2px solid #ffcd00; /* High-contrast focus outline */
}
