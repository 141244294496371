/* Wrapper for full-width white background */
.privacy-policy-wrapper {
  background-color: #ffffff;
  padding: 20px 0;
}

.privacy-policy-container {
  max-width: 800px;
  padding: 50px;
  margin: 0 auto;
  background-color: #ffffff;
  color: #002244;
  font-family: 'Poppins', sans-serif;
  line-height: 1.8;
}

/* Header Styling */
.privacy-policy-container h1 {
  font-size: 2.5rem;
  color: #0059b3;
  margin-bottom: 20px;
  text-align: center;
}

.privacy-policy-container h2 {
  font-size: 1.8rem;
  color: #003366;
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 2px solid #0059b3;
  padding-bottom: 5px;
}

.privacy-policy-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #333;
}

.privacy-policy-container ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 20px;
}

.privacy-policy-container ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.privacy-policy-container strong {
  color: #0059b3;
}

/* Link Styles */
.privacy-policy-container a {
  color: #0059b3;
  text-decoration: underline;
}

.privacy-policy-container a:hover {
  color: #003366;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 20px;
  }

  .privacy-policy-container h1 {
    font-size: 2rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.5rem;
  }

  .privacy-policy-container p,
  .privacy-policy-container ul li {
    font-size: 0.9rem;
  }
}
