/* Services Section */
.services-section {
  padding: 50px 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #003366;
  font-weight: bold;
}

/* Grid Layout */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
  padding-bottom: 30px;
}

/* Service Card */
.service-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.service-card:hover,
.service-card:focus {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
  font-size: 1.5rem;
  color: #003366;
  margin-bottom: 10px;
  font-weight: bold;
}

.service-card .service-description {
  font-size: 1rem;
  color: #444444;
  line-height: 1.6;
  margin-bottom: 10px;
}

.service-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #003366;
  margin-bottom: 10px;
}

.service-price .original-price {
  font-size: 1.2rem;
  color: #999999;
  text-decoration: line-through;
  margin-right: 10px;
}

.service-savings {
  font-size: 1.2rem;
  color: #ff6347;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Service Image */
.service-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  object-fit: cover;
  max-height: 200px;
}

/* Service Actions */
.service-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
}

.service-button {
  padding: 15px;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

.more-info-button {
  background-color: #ffcd00;
  color: #333333;
}

.more-info-button:hover {
  background-color: #e6b800;
  transform: scale(1.05);
}

.book-now-button {
  background-color: #0059b3;
  color: white;
}

.book-now-button:hover {
  background-color: #003366;
  transform: scale(1.05);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: #ffffff !important;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 80vh;
  text-align: left;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 2rem;
  font-weight: bold;
  color: #333333 !important;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: #ff6347 !important;
}

/* Modal Headings */
.modal-content h3 {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 15px;
  font-weight: bold;
}

.modal-content h4 {
  font-size: 1.5rem;
  color: #0059b3;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

/* Modal Text */
.modal-content p,
.modal-content li {
  font-size: 1rem;
  color: #444444;
  line-height: 1.8;
}

.modal-content ul {
  margin-left: 20px;
  list-style: disc;
}

/* Disclaimer */
.service-disclaimer {
  font-size: 0.95rem;
  color: #cc0000 !important;
  font-weight: bold;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

/* Accessibility */
.service-card:focus-visible {
  outline: 3px solid #0059b3;
  outline-offset: 4px;
}

/* Mobile Styling */
@media (max-width: 768px) {
  .service-card {
    text-align: center;
  }

  .service-button {
    font-size: 1rem;
  }

  .modal-content {
    padding: 20px;
    font-size: 0.95rem;
  }

  .modal-content h3 {
    font-size: 1.6rem;
  }

  .modal-content h4 {
    font-size: 1.3rem;
  }

  .service-image {
    max-height: 150px;
  }
}
