/* Wrapper for full-width white background */
.terms-and-conditions-wrapper {
  background-color: #ffffff;
  padding: 20px 0;
}

.terms-and-conditions-container {
  max-width: 800px;
  padding: 50px;
  margin: 0 auto;
  background-color: #ffffff;
  color: #003366;
  font-family: 'Poppins', sans-serif;
  line-height: 1.8;
}

/* Header Styling */
.terms-and-conditions-container h1 {
  font-size: 2.5rem;
  color: #0059b3;
  margin-bottom: 20px;
  text-align: center;
}

.terms-and-conditions-container h2 {
  font-size: 1.8rem;
  color: #003366;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 2px solid #0059b3;
  padding-bottom: 5px;
}

.terms-and-conditions-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #333;
}

.terms-and-conditions-container ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 20px;
}

.terms-and-conditions-container ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Accessibility: Skip Link */
.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #0059b3;
  color: white;
  padding: 8px 15px;
  z-index: 100;
  transition: top 0.3s;
}

.skip-link:focus {
  top: 10px;
}

/* Links */
.terms-and-conditions-container a {
  color: #0059b3;
  text-decoration: underline;
}

.terms-and-conditions-container a:hover {
  color: #003366;
}

@media (max-width: 768px) {
  .terms-and-conditions-container {
    padding: 20px;
  }

  .terms-and-conditions-container h1 {
    font-size: 2rem;
  }

  .terms-and-conditions-container h2 {
    font-size: 1.5rem;
  }

  .terms-and-conditions-container p,
  .terms-and-conditions-container ul li {
    font-size: 0.9rem;
  }
}
