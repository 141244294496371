/* Thank You Page CSS */
.thank-you-page {
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
}

.thank-you-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.thank-you-content h1 {
  color: #0059b3;
  font-size: 2.5rem;
  margin-bottom: 20px;
  line-height: 1.2;
}

.thank-you-content p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
}

.cta-button {
  padding: 15px 30px;
  background-color: #0059b3;
  color: white;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
  background-color: #004080;
  transform: scale(1.05);
}

.cta-button:focus {
  outline: 3px solid #ffcd00;
  outline-offset: 2px;
}     