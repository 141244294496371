/* Hero Section Styling */
.hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #001f3f;
    overflow: hidden; /* Ensure the image stays within bounds */
}

.hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Ensure the image stays behind the overlay and content */
    filter: brightness(70%); /* Darken the image for better text readability */
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for contrast */
    z-index: 1; /* Ensure overlay is above the background */
}

.hero-content {
    color: white;
    z-index: 2; /* Ensure content is above the overlay */
    width: auto; /* Ensure content is not full width */
    max-width: 80%; /* Optional: Limits the max width of the content */
    padding: 20px;
    position: absolute; /* Make the content float over the hero background */
    top: 50%; /* Center content vertically */
    left: 50%; /* Center content horizontally */
    transform: translate(-50%, -50%); /* Adjust for centering */
}

.hero-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    line-height: 1.2;
}

.hero-content p {
    font-size: 1.5rem;
    margin: 20px;
    line-height: 1.5;
}

.cta-button {
    padding: 15px 30px;
    background-color: #ffcd00;
    color: #001f3f;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: inline-block;
    margin-top: 20px;
    width: auto;
    max-width: 300px;
    text-align: center;
    outline: none;
}

.cta-button:hover,
.cta-button:focus {
    background-color: #e0a800;
    transform: scale(1.05);
    outline: 3px solid #ffffff;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-content p {
        font-size: 1.2rem;
    }

    .cta-button {
        width: 100%;
        max-width: none;
    }
}
