/* Contact Page CSS */
.contact-page {
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Hero Section */
.contact-hero {
  background-color: #0059b3;
  color: white;
  text-align: center;
  padding: 70px 20px;
}

.contact-hero-content h1 {
  color: white;
  font-size: 3rem;
  margin-bottom: 15px;
  font-weight: 700;
}

.contact-hero-content p {
  font-size: 1.3rem;
  margin: 0 auto;
  max-width: 800px;
}

/* Contact Form Section */
.contact-form-section {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.contact-form-section h2 {
  color: #0059b3;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 600;
}

.contact-form-section p {
  margin-bottom: 30px;
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto 30px auto;
}

.contact-form {
  max-width: 700px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 25px; /* Increase margin between form groups */
  text-align: left;
}

.form-group label {
  font-size: 1rem;
  font-weight: 600;
  display: block;
  margin: 10px;
  color: #003366; /* Increased contrast for better readability */
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: 2px solid #0059b3; /* Improve focus visibility */
  box-shadow: 0 0 5px rgba(0, 89, 179, 0.5);
}

.cta-button {
  padding: 15px 30px;
  background-color: #0059b3;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #004080;
}

.cta-button:focus {
  outline: 2px solid #ffcd00; /* Distinct focus state */
  box-shadow: 0 0 5px rgba(255, 205, 0, 0.5);
}

/* Contact Details Section */
.contact-details {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.contact-details h2 {
  color: #0059b3;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
}

.details-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.detail {
  max-width: 300px;
  margin-bottom: 30px;
}

.detail h3 {
  color: #003366; /* Increased contrast for better readability */
  font-size: 1.3rem;
  margin-bottom: 10px;
  font-weight: 600;
}

.detail p {
  font-size: 1.1rem;
  color: #555;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
  .form-group {
      width: 80%; /* Make the entire form-group narrower */
      margin: 0 auto; /* Center the label and input fields */
  }
  .form-group label,
  .form-group input,
  .form-group textarea {
      display: block;
      width: 100%; /* Keep input/textarea widths relative to the narrower form-group */
  }
}
